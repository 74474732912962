/* color palette */

:root {
  --base-c-black: #000000;

  --base-c-white: #ffffff;
  --base-c-white-soft: #f8f8f8;
  --base-c-white-mute: #f6f6f6;
  --base-c-white-faded: rgba(248, 248, 248, 0.85);

  --base-c-grey-light: #cec3bd;
  --base-c-grey-dark: #545454;

  --base-c-beige: #f8f7ea;
  --base-c-beige-soft: #e1e0c7;
  --base-c-beige-dark: #cbc1b9;

  --base-c-green: #233e3a;
  --base-c-green-soft: #44655a;

  --base-c-divider-light-1: rgba(60, 60, 60, 0.29);
  --base-c-divider-light-2: rgba(60, 60, 60, 0.12);
  --base-c-divider-dark-1: rgba(84, 84, 84, 0.65);
  --base-c-divider-dark-2: rgba(84, 84, 84, 0.48);

  --base-box-shadow: rgba(0, 0, 0, 0.25);
  --base-background-filter: rgba(0, 0, 0, 0.4);

  --base-c-text-light-1: var(--base-c-beige);
  --base-c-text-light-2: var(--base-c-beige-soft);
  --base-c-text-dark-1: var(--base-c-green);
  --base-c-text-dark-2: var(--base-c-grey-dark);
}

/* semantic color variables */

:root {
  --color-background: var(--base-c-beige);
  --color-background-sombre: var(--base-c-beige-soft);
  --color-background-dark: var(--base-c-beige-dark);

  --color-border: var(--base-c-divider-light-2);
  --color-border-focus: var(--base-c-divider-light-1);

  --color-heading-light: var(--base-c-text-light-1);
  --color-heading-dark: var(--base-c-text-dark-1);

  --color-text-light: var(--base-c-text-light-1);
  --color-text-dark: var(--base-c-text-dark-1);
}

/* Responsive Font Size */

:root {
  --fs-100: 1.6rem;
  --fs-200: calc(1.7em + 0.390625vw);
  --fs-300: calc(2em + 0.390625vw);
  --fs-400: calc(2.6em + 0.390625vw);
  --fs-500: calc(3.1em + 0.390625vw);
  --fs-600: calc(4em + 0.390625vw);
}

/* 
 Breakpoints

576px / 36em for portrait phones.
768px / 48em for tablets.
992px / 62em for laptops.
1200px / 75em for large devices.

*/
