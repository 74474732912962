@import "./base.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

/* CSS basic rules set up */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Open Sans", "Montserrat";
  scroll-behavior: smooth;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.4;

  background-color: var(--base-c-white-soft);
}

body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-family: "Montserrat";
}

p,
a,
li {
  font-family: "Open Sans";
}

h1 {
  font-size: var(--fs-600);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;

  line-height: 1.2;

  color: var(--color-heading-light);
}

h2 {
  font-size: var(--fs-300);
  font-weight: 500;

  color: var(--color-heading-dark);
  text-transform: uppercase;
  letter-spacing: 0.125em;
}

h3 {
  font-size: var(--fs-300);
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  color: var(--color-heading-light);
}

h4 {
  font-size: var(--fs-300);
  color: var(--color-heading-light);

  font-weight: 500;
  text-transform: uppercase;

  text-align: center;
}

h5 {
  font-size: var(--fs-300);
  color: var(--color-heading-dark);

  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2rem;

  text-align: center;
}

p {
  font-size: var(--fs-100);
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}
